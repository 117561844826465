import { format, parseISO } from 'date-fns'
import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import RedemptionFilterBar from './RedemptionFilterBar'

const fetchRedemptions = async (query, filters) => {
  const search = filters.search || ''
  const companyIds = filters.companyIds || ''
  const rewardingTypeId = filters.rewardingTypeId || ''
  const state = filters.state || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''

  const { items: data, total: totalCount } = await request.get(
    `/admin/redemptions?search=${search}&companyIds=${companyIds}&rewardingTypeId=${rewardingTypeId}&state=${state}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )

  const offset = query.pageSize * query.page

  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function RedemptionTable() {
  const companiesSectors = useCompaniesSectors()
  const [filters, setFilters] = useState({
    search: '',
    companyIds: [],
    archived: false,
  })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { openConfirmDialog } = useConfirmDialog()
  const classes = useStyles()

  const columns = [
    { title: t('redemptionsPage.tableHeaders.userName'), field: 'userName' },
    { title: t('redemptionsPage.tableHeaders.email'), field: 'email' },
    {
      title: t('redemptionsPage.tableHeaders.companyName'),
      field: 'companyName',
    },
    {
      title: t('redemptionsPage.tableHeaders.rewardingTypeName'),
      field: 'rewardingTypeI18nLabel',
      render: (params) =>
        t(`entities.rewardingTypes.${params.rewardingTypeI18nLabel}`),
    },
    { title: t('redemptionsPage.tableHeaders.amount'), field: 'amount' },
    { title: t('redemptionsPage.tableHeaders.state'), field: 'state' },
    {
      title: t('redemptionsPage.tableHeaders.requestDate'),
      field: 'requestDate',
      render: (params) =>
        params.requestDate
          ? format(parseISO(params.requestDate), 'dd/MM/yyyy HH:mm')
          : '-',
    },
    {
      title: t('redemptionsPage.tableHeaders.emissionDate'),
      field: 'emissionDate',
      render: (params) =>
        params.emissionDate
          ? format(parseISO(params.emissionDate), 'dd/MM/yyyy HH:mm')
          : '',
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    (query) => {
      return fetchRedemptions(query, filters).catch((e) => {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      })
    },
    [filters, showSnackbarMessage, t]
  )

  async function handleComplete(rewardId, userName, amount) {
    openConfirmDialog({
      title: t('redemptionsPage.completeTitle'),
      description: t('redemptionsPage.completeDescription', {
        userName,
        amount,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.post(`/admin/redemptions/${rewardId}/complete`)
          showSnackbarMessage({ open: true, severity: 'success' })
          tableRef.current.onQueryChange()
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  return (
    <Card className={classes.root}>
      <RedemptionFilterBar
        filters={filters}
        setFilters={setFilters}
        companiesSectors={companiesSectors}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.complete'),
            onClick: async (e, rowData) => {
              e.preventDefault()
              await handleComplete(rowData.id, rowData.userName, rowData.amount)
            },
            hidden: (rowData) => rowData.state !== 'REQUESTED',
          },
        ]}
      />
    </Card>
  )
}
